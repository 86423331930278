import React, { useEffect, useCallback, useRef } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import { AcIsSet } from '@utils';

// Imports => Molecules
import AcMultiSelect from '@molecules/ac-multi-select/ac-multi-select';

// Imports => Components
import { AcCompanySearch } from '@components/ac-company-search';

// Imports => Atoms
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const AcSelectErpCompany = ({ store: { erp } }) => {
  const {
    current_companies,
    current_company,
    current_company_query,
    is_loading,
  } = erp;

  const didMount = useRef(false);

  useEffect(() => {
    erp.resetCompanies();
    erp.resetCompany();
  }, []);

  useEffect(() => {
    if (didMount.current) {
      if (current_company_query) erp.listAllCompanies();
    } else {
      didMount.current = true;
      erp.resetCompanies();
    }
  }, [current_company_query]);

  const handleOnFilterChange = useCallback(
    async (filterValue, force = false) => {
      erp.setCompaniesQuery(filterValue);

      if (!current_company_query) {
        erp.resetCompany();
      }
      if (!filterValue) {
        erp.resetCompanies();
      }
      if (AcIsSet(filterValue) && filterValue !== '' && force) {
        // await erp.resetCompany();
        erp.listAllCompanies();
      } else if (
        current_company_query &&
        filterValue !== undefined &&
        filterValue !== current_company_query
      ) {
        if (current_company) {
          erp.resetCompany();
        }
      }
    }
  );

  const handleSelectCompany = useCallback(
    (arr) => {
      const item = arr?.[0];

      if (current_companies && item) {
        erp.set(
          KEYS.COMPANY,
          current_companies.find((company) => company.erp_id === item)
        );
      } else {
        erp.resetCompany();
      }
    },
    [current_companies]
  );

  return (
    <div className={'ac-wizard-content'}>
      {/*<div className={'ac-wizard-step--header'}>
        <div className={'ac-wizard-step--fill-h'}>
          Select the company you want to import from ERP
        </div>
      </div>*/}

      {/*<AcCompanySearch
        value={current_company && current_company.name}
        onSelectItem={handleSelectCompany}
        results={current_companies}
        loading={is_loading}
        filter={handleOnFilterChange}
      />*/}

      <div className={'ac-multi-select-modal'}>
        <div className={'ac-multi-select-modal__content'}>
          <AcMultiSelect
            {...{
              instance: erp,
              collection: current_companies.map((item) => ({
                ...item,
                id: item.erp_id,
              })),
              introduction:
                '<p>Find and select the company you want to import from the ERP</p>',
              multiple: false,
              highlight: null,
              feedback: null,
              initialQuery: current_company_query,
              initialSelection: current_company?.erp_id,
              onChange: handleSelectCompany,
              onSearch: handleOnFilterChange,
            }}
          />

          {is_loading && (
            <div className={'ac-wizard-loading'}>
              <AcLoader loading={true} cover />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withStore(observer(AcSelectErpCompany));
